import styled from 'styled-components';

import { cssBreakpoints } from 'theme/theme';

const heightVar = '--idealist-logo-height';

export const IdealistLogoHolder = styled.div<{
  $heightMobile: number;
  $heightDesktop: number;
  $showStaffMarker?: boolean;
}>`
  display: inline-flex;
  align-items: center;

  ${heightVar}: ${(props) => props.$heightDesktop}px;

  @media screen and (max-width: ${cssBreakpoints.mdDown}) {
    ${heightVar}: ${(props) => props.$heightMobile}px;
  }

  height: var(${heightVar});
  gap: calc(var(${heightVar}) / 4);
`;

export const IdealistLogoMarkImg = styled.img`
  display: inline-flex;
  width: auto;
  height: 100%;
  object-fit: contain;
  object-position: center;
`;

export const IdealistLogoTextImg = styled.img`
  width: auto;
  height: 100%;
  object-fit: contain;
  object-position: center;
`;

export const IdealistLogoStaffMarkerImg = styled.img`
  width: auto;
  height: 100%;
  object-fit: contain;
  object-position: center;
`;
