import { getStaticImageSrc } from 'utils/getImageSrc';

import {
  IdealistLogoHolder,
  IdealistLogoMarkImg,
  IdealistLogoStaffMarkerImg,
  IdealistLogoTextImg,
} from './IdealistLogo.styled';

type Props = {
  height: number | { mobile: number; desktop: number };
  withText?: boolean;
  withStaffMarker?: boolean;
};

export function IdealistLogo({ height, withText, withStaffMarker }: Props) {
  return (
    <IdealistLogoHolder
      $heightMobile={typeof height === 'number' ? height : height.mobile}
      $heightDesktop={typeof height === 'number' ? height : height.desktop}
    >
      <IdealistLogoMarkImg
        src={getStaticImageSrc('logos/logo-mark.webp')}
        alt="Idealist logo"
        width={167}
        height={168}
      />

      {withText && (
        <IdealistLogoTextImg
          src={getStaticImageSrc('logos/logo-idealist.svg')}
          alt="idealist"
          width={348}
          height={168}
        />
      )}

      {withStaffMarker && (
        <IdealistLogoStaffMarkerImg
          src={getStaticImageSrc('logos/logo-staff.svg')}
          alt="staff"
          width={184}
          height={168}
        />
      )}
    </IdealistLogoHolder>
  );
}
